<template>
  <div>
    <div style="padding: 10px 50px">
      <h3>{{ $store.state.common.sScreenTitle }}</h3>
      <hr />
    </div>

    <div v-show="$store.state.common.sIsLoading">Loading...</div>
    <div v-show="!$store.state.common.sIsLoading">
      <div class="uk-flex uk-flex-center">
        <div class="uk-container">
          <div class="uk-text-center" uk-grid>
            <div
              v-if="
                vStep == $store.state.firstregistration.sFirstRegiStep.in_verify
              "
              class="uk-width-1-1"
            >
              <h2>
                Step:{{ vStep }}
                {{ $store.state.firstregistration.sLblVerifyCode }}入力
              </h2>
              <div>
                <progress class="uk-progress" value="20" max="30"></progress>
              </div>
              <hr />
              <p>
                <b
                  >ご入力いただいたメールアドレスへ確認コードを送信しました。</b
                >
                <br />
                <b>確認コードを入力してください。</b>
                <br />
                <b>※確認コードの有効期限は10分間です。</b>
              </p>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <input class="uk-input" type="text" v-model="vVerifyCode" />
                </div>
                <button
                  :disabled="$store.state.common.sServerProcessing"
                  class="uk-button uk-button-secondary"
                  type="button"
                  @click="doSwitchDisplay"
                >
                  次へ
                </button>
              </div>
            </div>
            <div
              v-else-if="
                vStep ==
                $store.state.firstregistration.sFirstRegiStep.in_password
              "
              class="uk-width-1-1"
            >
              <h2>
                Step:{{ vStep }}
                {{ $store.state.firstregistration.sLblPassword }}入力
              </h2>
              <div>
                <progress class="uk-progress" value="30" max="30"></progress>
              </div>
              <hr />
              <p>
                <b>企業名と任意のパスワードを入力してください。</b><br /><b
                  >※パスワードは半角英小文字・大文字・数字を含む8桁以上14桁以下で入力してください。</b
                >
              </p>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">{{
                      $store.state.firstregistration.sLblComName
                    }}</label>
                  </div>
                  <input class="uk-input" type="text" v-model="vComName" />
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted">{{
                      $store.state.firstregistration.sLblPassword
                    }}</label>
                  </div>
                  <input class="uk-input" :type="vInputType" v-model="vPw" />
                  <span
                    uk-icon="icon: unlock"
                    @click="
                      doSwitchPassword(
                        $store.state.firstregistration.sPasswordInputArea
                          .original
                      )
                    "
                  ></span>
                </div>
              </div>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <div style="text-align: left">
                    <label class="uk-form-label uk-text-muted"
                      >{{
                        $store.state.firstregistration.sLblPassword
                      }}（確認）</label
                    >
                  </div>
                  <input
                    class="uk-input"
                    :type="vInputTypeConfirm"
                    v-model="vPwConfirm"
                  />
                  <span
                    uk-icon="icon: unlock"
                    @click="
                      doSwitchPassword(
                        $store.state.firstregistration.sPasswordInputArea
                          .confirm
                      )
                    "
                  ></span>
                </div>
              </div>
              <div class="uk-width-1-1">
                <button
                  :disabled="$store.state.common.sServerProcessing"
                  class="uk-button uk-button-secondary"
                  type="button"
                  @click="doSwitchDisplay"
                >
                  登録
                </button>
              </div>
            </div>
            <div v-else class="uk-width-1-1">
              <h2>
                Step:{{ vStep }}
                {{ $store.state.firstregistration.sLblMailAddress }}入力
              </h2>
              <div>
                <progress class="uk-progress" value="10" max="30"></progress>
              </div>
              <hr />
              <p>
                <b
                  >{{
                    $store.state.firstregistration.sLblMailAddress
                  }}を入力してください。</b
                >
              </p>
              <div class="uk-width-1-1">
                <div class="uk-margin">
                  <input class="uk-input" type="text" v-model="vMailAddress" />
                </div>
                <button
                  :disabled="$store.state.common.sServerProcessing"
                  class="uk-button uk-button-secondary"
                  type="button"
                  @click="doSwitchDisplay"
                >
                  次へ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p style="color: red">
        <b>{{ vMessage }}</b>
      </p>
    </div>
  </div>
</template>

<script>
import constant from "@/constant.js";

export default {
  data() {
    return {
      vStep: this.$store.state.firstregistration.sFirstRegiStep.in_mailaddress,
      vVerifyId: "",
      vMailAddress: "",
      vVerifyCode: "",
      vComName: "",
      vPw: "",
      vPwConfirm: "",
      vMessage: "",
      vInputType: "password",
      vInputTypeConfirm: "password",
    };
  },
  created() {
    // 画面読み込み中・・・
    this.$store.state.common.sIsLoading = true;
    // 画面タイトル
    this.$store.commit("common/doSetScreenTitle", {
      vScreenTitle: "初回登録",
    });
    // // テスト用
    // this.vStep = 3;
    // this.vMailAddress = 't-shigeta@holonicsystem.com';
  },
  // 画面読み込み終わった？
  mounted() {
    this.$store.state.common.sIsLoading = false;
  },
  methods: {
    // パスワード表示切替処理
    doSwitchPassword(mode) {
      switch (mode) {
        case this.$store.state.firstregistration.sPasswordInputArea.original:
          if (this.vInputType == "password") {
            this.vInputType = "text";
          } else {
            this.vInputType = "password";
          }
          break;
        case this.$store.state.firstregistration.sPasswordInputArea.confirm:
          if (this.vInputTypeConfirm == "password") {
            this.vInputTypeConfirm = "text";
          } else {
            this.vInputTypeConfirm = "password";
          }
          break;
      }
    },
    // Step切替処理
    doSwitchDisplay() {
      // ボタン制御
      this.$store.commit("common/doStartServerProcess");
      switch (this.vStep) {
        // 確認コードの入力
        case this.$store.state.firstregistration.sFirstRegiStep.in_verify:
          if (this.vVerifyId && this.vMailAddress && this.vVerifyCode) {
            // 確認コードデータを参照する
            this.vMessage = "";
            this.$axios
              .post(constant.cServerRoute.common.first_registration, {
                pVerifyId: this.vVerifyId,
                pMailAddress: this.vMailAddress,
                pVerifyCode: this.vVerifyCode,
                pStep: this.vStep,
              })
              .then(
                function (response) {
                  console.log(response);
                  // 確認コード照合処理判定
                  if (
                    response.data.return_result ==
                    constant.cServerReturnResult.success
                  ) {
                    // 表示を切り替える（パスワード入力画面）
                    this.vStep =
                      this.$store.state.firstregistration.sFirstRegiStep.in_password;
                  } else {
                    this.vMessage = response.data.return_message;
                  }
                  // ボタン制御
                  this.$store.commit("common/doEndServerProcess");
                }.bind(this)
              )
              .catch(function (error) {
                console.log(error);
                this.vMessage = error;
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              });
          }
          break;
        // パスワードの入力
        case this.$store.state.firstregistration.sFirstRegiStep.in_password:
          if (this.vMailAddress && this.vPw && this.vPwConfirm) {
            this.vMessage = "";
            // パスワード要件判定
            if (
              !this.vPw.match(
                /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,14}$/
              )
            ) {
              this.vMessage =
                "パスワードは半角英小文字・大文字・数字を含む8桁以上14桁以下で入力してください";
              return;
            }
            // パスワードと確認用の照合
            if (this.vPw != this.vPwConfirm) {
              this.vMessage = "パスワードと確認用パスワードが異なります";
              return;
            }
            // 企業情報を仮登録する
            this.$axios
              .post(constant.cServerRoute.common.first_registration, {
                pMailAddress: this.vMailAddress,
                pPw: this.vPw,
                pComName: this.vComName,
                pStep: this.vStep,
              })
              .then(
                function (response) {
                  console.log(response);
                  // 企業情報仮登録処理判定
                  if (
                    response.data.return_result ==
                    constant.cServerReturnResult.success
                  ) {
                    // ログイン画面へ遷移する
                    this.vStep =
                      this.$store.state.firstregistration.sFirstRegiStep.in_done;
                    if (
                      this.vStep ==
                      this.$store.state.firstregistration.sFirstRegiStep.in_done
                    ) {
                      this.$router.push("/login");
                    }
                    // ボタン制御
                    this.$store.commit("common/doEndServerProcess");
                  } else {
                    this.vMessage = response.data.return_message;
                    // ボタン制御
                    this.$store.commit("common/doEndServerProcess");
                  }
                }.bind(this)
              )
              .catch(function (error) {
                console.log(error);
                this.vMessage = error;
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              });
          }
          break;
        // メールアドレスの入力
        default:
          if (this.vMailAddress) {
            // 確認コードデータを登録する
            this.vMessage = "";
            this.$axios
              .post(constant.cServerRoute.common.first_registration, {
                pMailAddress: this.vMailAddress,
                pStep: this.vStep,
              })
              .then(
                function (response) {
                  console.log(response);
                  if (
                    response.data.return_result ==
                    constant.cServerReturnResult.success
                  ) {
                    // 確認IDを保持する
                    this.vVerifyId = response.data.return_verify_id;
                    // 表示を切り替える（確認コード入力画面）
                    this.vStep =
                      this.$store.state.firstregistration.sFirstRegiStep.in_verify;
                    // ボタン制御
                    this.$store.commit("common/doEndServerProcess");
                  } else {
                    this.vMessage = response.data.return_message;
                    // ボタン制御
                    this.$store.commit("common/doEndServerProcess");
                  }
                }.bind(this)
              )
              .catch(function (error) {
                console.log(error);
                this.vMessage = error;
                // ボタン制御
                this.$store.commit("common/doEndServerProcess");
              });
          }
          break;
      }
    },
  },
};
</script>
